window.i18n = {
  sidebar: {
    information: "Information",
    playerSearch: "Player search",
    leaderboard: "Leaderboard",
    highlights: "Highlights",
    fullscreenIn: "Fullscreen",
    fullscreenExit: "Fullscreen",
    programme: "Practice",
    results: "Results",
    schedule: "Daily schedule",
    matchplayPlayers: "Player search",
    matchplayLeaderboard: "Scoring",
  },
  slider: {
    round: "Round",
    information: "Information",
    playerSearch: "Player search",
    leaderboard: "Leaderboard",
    highlights: "Highlights",
    location: "Location",
    programme: "Practice Groups",
    today: "Today",
    results: "Results",
    schedule: "Daily schedule",
    matchplayPlayers: "Player search",
    matchplayLeaderboard: "Scoring",
  },
  information: {
    "show-all-layers": "Show all layers",
    groups: "Groups",
    hole: "Holes",
  },
  leaderboard: {
    noDataAvailable: "There is no scoring data available yet.",
  },
  leaderboardHeader: {
    pos: "Pos",
    name: "Name",
    par72: "Par 72",
    thru: "Thru",
    today: "Today",
    total: "Total",
    favorite: "Fav",
  },
  autocomplete: {
    player: "Name",
    tee: "Tee",
    thru: "Thru",
    group: "Group",
    placeholder: "Search player",
    noDataAvailable: "There is no draw data available yet.",
  },
  popupContent: {
    group: "Group",
    tee: "Tee",
    hole: "Hole",
    players: "Players",
    pos: "Pos",
    par: "Total",
    thru: "Thru",
    today: "Today",
  },
  popupContentMatchPlay: {
    round: "round",
    match: "match",
    hole: "hole",
  },
  playersOnThisArea: "Players currently on this area:",
  backToHome: {
    title: "Back to home",
  },
  zoomIn: {
    title: "Zoom in",
  },
  zoomOut: {
    title: "Zoom out",
  },
  location: {
    denied: {
      first: "You've decided not to share your position.",
      second:
        "But if you change your mind, please find below how to change it.",
    },
    unavailable:
      "The network is down or the location service can't be reached.",
    timeout: "The attempt timed out before it could get the location data.",
    error: "Geolocation failed due to unknown error.",
    "on-off": "How to turn GPS Location On/Off:",
    maxBound: {
      title: "Location",
      text: "You are currently outside the event location, we will display your position once you get closer to our location.",
    },
    android: {
      swipe: "Swipe down from the top of the screen.",
      touch: "Touch and hold Location",
      location: "If you don't find Location",
      edit: "Tap Edit",
      settings: "or Settings",
      drag: "Drag Location",
      quick: "into your Quick Settings",
    },
    apple: {
      settings: "Go to Settings",
      privacy: "Privacy",
      services: "Location Services.",
      location: "Make sure that Location Services is on.",
    },
  },
  info: {
    message: "3 Hole scoring - no live player data available.",
  },
  tennisEventTypeName: {
    MS: "Men Single",
    MD: "Men Double",
    QS: "Men Qualifier Single",
    QD: "Men Qualifier Double",
    LS: "Ladies Single",
    LD: "Ladies Double",
    RS: "Qualifier Ladies Single",
    RD: "Qualifier Ladies Double",
  },
};
